<template>
  <div v-if="activeChartRender" class="pilot-chart-limitation pt-4">
    <apexchart ref="pilotChartLimitation" type="area" height="270px" :options="chartOptions" :series="chartSeries" />
    <div class="px-4 pb-6">
      <v-autocomplete
        label="Add another pilot to the chart"
        v-model.number="newPilotData"
        :items="filterPilot($store.state.logbook.pilots)"
        item-text="fullname"
        item-value="id"
        hide-details
        outlined
        dense
      />
    </div>
    <span class="reset-chart-items" :class="chartSeries.length < 2 ? 'disable' : ''" @click.prevent="ClearSeries()">
      Reset
    </span>
    <span class="loading-chart" :class="[loadingData < 5 ? 'show' : '']">
      Loading data, please wait ...
    </span>
    <TableLimit v-if="activeChartRender" :benchmark="true" :categories="chartOptions.xaxis.categories" :series="chartSeries" :limit="rangeLimit" />
  </div>
</template>

<script>
export default {
  name: 'ChartLimitation',
  props: {
    rangeLimit: {
      type: Number,
      default: 0
    },
    rangeSlider: {
      type: Number,
      default: 0
    },
    init: {
      type: Object,
      default: () => null
    },
    limit: {
      type: Number,
      default: 0
    }
  },
  data: (vm) => ({
    activeChartRender: false,
    chartOptions: {
      id: 'pilotChartLimitation',
      title: {
        enable: false,
        text: 'Limit Benchmark',
        align: 'left'
      },
      chart: {
        stacked: false,
        toolbar: {
          show: false
        },
        events: {
          click: vm.ChartClick
        }
      },
      tooltip: {
        y: {
          formatter: vm.tooltipFormat,
          title: {
            formatter: (seriesName) => seriesName + ': '
          }
        }
      },
      grid: {
        show: true,
        borderColor: '#f0f0f0',
        strokeDashArray: 2,
        position: 'back',
        row: {
          opacity: 0.2
        },
        column: {
          opacity: 0.2
        },
        padding: {
          top: 10,
          right: 20,
          bottom: 0,
          left: 10
        }
      },
      legend: {
        fontSize: '12px',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 400,
        position: 'bottom',
        horizontalAlign: 'center',
        itemMargin: {
          horizontal: 20,
          vertical: 7
        },
        markers: {
          width: 12,
          height: 12
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        orientation: 'vertical',
        formatter: function (val) {
          return val + 'h'
        },
        offsetY: -10,
        style: {
          fontSize: '6px',
          fontWeight: 'normal'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100]
        }
      },
      stroke: {
        width: 1,
        curve: 'smooth'
      },
      annotations: {
        xaxis: [
          {
            x: vm.$DateReformat(vm.$TodayDate()),
            borderColor: '#00AA00',
            strokeDashArray: 1,
            label: {
              borderColor: '#00AA00',
              style: {
                fontSize: '10px',
                color: '#fff',
                background: 'rgba(0, 150, 0, .5)'
              },
              orientation: 'horizontal',
              offsetY: 7,
              text: 'Today'
            }
          }
        ]
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: '6px'
          }
        }
      },
      yaxis: {
        min: 0,
        max: 0,
        labels: {
          style: {
            fontSize: '10px'
          },
          formatter: (v) => {
            return v.toFixed(0) + 'h'
          }
        }
      }
    },
    chartSeries: [],
    newPilotData: 0,
    loadingData: 5,
    limitations: []
  }),
  watch: {
    newPilotData: function (pid) {
      if (parseInt(pid)) {
        if (this.chartSeries.length < 5) {
          this.addNewPilotData(pid)
        } else {
          this.$toastNotif({
            message: 'Maximum 5 items',
            type: 'info'
          })
        }
      }
      setTimeout(() => {
        this.newPilotData = 0
        setTimeout(() => {
          document.getElementById('app').click()
        }, 100)
      }, 100)
    }
  },
  created () {
    if (this.init) {
      this.chartOptions.xaxis.categories = this.init.data.map(r => r.date)
    }
    this.chartOptions.yaxis.max = this.limit
    this.AddSeries(null, this.init.data)
    this.activeChartRender = true
  },
  methods: {
    ClearSeries () {
      if (this.chartSeries.length < 2) {
        return false
      }
      const getFirstIndex = this.chartSeries[0]
      this.chartSeries = []
      this.chartSeries.push(getFirstIndex)
    },
    NewLimitation (index, data) {
      if (index === 0 || index > 0) {
        this.limitations[index].id = data.id
        this.limitations[index].name = data.name
      } else {
        this.limitations.push({
          id: parseInt(data.id),
          name: data.name,
          data: []
        })
      }
    },
    AddLimitation (index, data) {
      const foundIndex = this.limitations[index].data.findIndex(r => r.date === data.date)
      if (foundIndex > -1) {
        this.limitations[index].data[foundIndex].data = parseFloat(data.value) || 0
      } else {
        this.limitations[index].data.push({
          date: data.date,
          data: parseFloat(data.value) || 0
        })
      }
    },
    async addNewPilotData (pid) {
      const Pilot = await this.$store.state.logbook.pilots.find(r => parseInt(pid) === parseInt(r.id))
      this.limitations = await []
      await this.NewLimitation(null, {
        id: parseInt(Pilot.id),
        name: Pilot.name
      })
      await this.loadChartData(pid, this.rangeLimit, this.rangeSlider)

      await this.AddSeries(Pilot, this.limitations[0].data)
    },
    async loadChartData (pid, limit, range) {
      const maxDate = await this.$DateAddDays(this.$TodayDate(), range / 2)
      await this.$store.dispatch('PILOT_LOGBOOK_GET', {
        id: pid,
        q: '?by=total&rangeLimit=' + limit + '&rangeShow=' + range + '&maxDate=' + maxDate
      }).then((res) => {
        if (res.status) {
          res.data.data.map((r) => {
            r.ft_total = parseFloat(r.ft_total) || 0
            this.AddLimitation(0, {
              date: this.$DateReformat(r.selected_date),
              name: 'Datas',
              value: parseFloat(r.ft_total)
            })
            return r.ft_total
          })
        }
      })
    },
    sumPrevFlighttime (ft, dataRangeSum) {
      let ftSum = 0
      for (let i = 0; i < dataRangeSum.length; i++) {
        ftSum += parseFloat(dataRangeSum[i].flighttime)
      }
      return ftSum
    },
    AddSeries (p, data) {
      const pilot = Object.assign({}, p || this.$store.state.me)
      const Series = data.map((r) => {
        return r.data
      })
      this.chartSeries.push({
        id: parseInt(pilot.id),
        name: pilot.name,
        source: data,
        data: Series
      })
    },
    GetMaxData (data, pilot) {
      const fl1 = parseFloat(data.fl1) * 100 / (parseInt(pilot.single_crew) ? 6 : 9)
      const fl7 = parseFloat(data.fl7) * 100 / (parseInt(pilot.aircraft_weight) > 5700 ? 35 : 40)
      const fl30 = parseFloat(data.fl30) * 100 / 100
      const fl90 = parseFloat(data.fl90) * 100 / 300
      const fl365 = parseFloat(data.fl365) * 100 / 1050
      // const average = (fl1 + fl7 + fl30 + fl90 + fl365) / 5
      const seriesMax = Math.max(fl1, fl7, fl30, fl90, fl365)
      return {
        value: seriesMax === fl1
          ? parseFloat(data.fl1)
          : seriesMax === fl7
            ? parseFloat(data.fl7)
            : seriesMax === fl30
              ? parseFloat(data.fl30)
              : seriesMax === fl90
                ? parseFloat(data.fl90)
                : parseFloat(data.fl365),
        limit: seriesMax === fl1
          ? (parseInt(pilot.single_crew) ? 6 : 9)
          : seriesMax === fl7
            ? (parseInt(pilot.aircraft_weight) > 5700 ? 35 : 40)
            : seriesMax === fl30
              ? 100
              : seriesMax === fl90
                ? 300 : 1050,
        percent: parseInt(seriesMax)
      }
    },
    ChartClick (chart, seriesIndex, opts) {
      const el = chart.target
      if (el) {
      }
    },
    tooltipFormat (value, { series, seriesIndex, dataPointIndex, w }) {
      // const date = this.chartOptions.xaxis.categories[dataPointIndex]
      // const pilot = this.$store.state.logbook.pilots.find(r => parseInt(r.id) === parseInt(this.chartSeries[seriesIndex].id))
      // const findSource = this.chartSeries[seriesIndex].source[dataPointIndex].data
      // const seriesMax = this.GetMaxData(findSource, pilot)
      const result = value + 'h/' + (this.limit + 'h')
      return `
        <div>
          <div>` + result + `</div>
        <div>
      `
    },
    filterPilot (p) {
      return p.filter(r => {
        const findPilot = this.chartSeries.find(pr => parseInt(pr.id) === parseInt(r.id))
        if (findPilot) {
          return false
        }
        return true
      })
    }
  }
}
</script>

<style lang="scss">
@import './../assets/scss/fonts';

.pilot-chart-limitation {
  position: relative;
  >.loading-chart {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding-top: calc(50% - 25px);
    text-align: center;
    color: #fff;
    background: rgba(1, 7, 56, 0.7);
    border-radius: 10px;
    transition: opacity .5s ease;
    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }
  >.reset-chart-items {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    @include font-size(14px);
    border-radius: 4px;
    padding: 2px 10px;
    border: solid 1px #ff5555;
    color: #ff5555;
    &.disable {
      opacity: .3;
    }
  }
}
</style>
